<template>
  <div
    class="container order-search"
    style="margin-top: 6.5vh; padding-left: 2vw; padding-right: 2vw">
    <h2>Template Filters</h2>
    <hr />
    <b-card>
      <b-card-title>
        <span
          @click="filter.basicFieldsExpaned = !filter.basicFieldsExpaned"
          class="pointer"
          :aria-expanded="true"
          aria-controls="collapse-basic"
        >
        Basic Search Fields
        <i :class="[
          filter.basicFieldsExpaned
            ? 'fas fa-angle-up'
            : 'fas fa-angle-down',
            'pl-2'
          ]"></i>
        </span>
      </b-card-title>

      <b-collapse id="collapse-basic" v-model="filter.basicFieldsExpaned">
        <b-form-row class="mb-3">
          <b-col cols="12">
            <b>Template Name</b>
            <b-form-textarea id="txtTemplateName"
                             v-model="filter.templateName">
            </b-form-textarea>
          </b-col>
        </b-form-row>
        <div class="form-row">
          <div class="col-md-12">
            <b>Delivery Site</b>
            <tf-consignee-multi-select :customerId="loggedInUserCustomerId"
                                       :userId="loggedInUserId"
                                       includeReadOnly="true">
            </tf-consignee-multi-select>
          </div>

        </div>
        <div class="form-row">
          <div class="col-md-6">
            <b>Delivery City</b>
            <tf-cities-multi-select></tf-cities-multi-select>
          </div>

          <div class="col-md-6">
            <b>Delivery State</b>
            <tf-states-multi-select></tf-states-multi-select>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <b-card>
      <b-card-title>
        <span @click="filter.advancedFieldsExpaned = !filter.advancedFieldsExpaned"
            :aria-expanded="false"
            aria-controls="collapse-advanced"
            class="pointer">
        Advanced Search Fields
        <i :class="[
              filter.advancedFieldsExpaned
                ? 'fas fa-angle-up'
                : 'fas fa-angle-down',
              'pl-2'
            ]"></i>
      </span>
    </b-card-title>
      <b-collapse id="collapse-advanced" v-model="filter.advancedFieldsExpaned">
        <div class="form-row">
          <div class=" col-md-6">
            <b>BillTo</b>

            <tf-bill-to-multi-select :customerId="loggedInUserCustomerId"
                                     :userId="loggedInUserId"></tf-bill-to-multi-select>
          </div>
          <div class=" col-md-6">
            <b>Account Of</b>

            <tf-account-of-multi-select :customerId="loggedInUserCustomerId"
                                        :userId="loggedInUserId"></tf-account-of-multi-select>
          </div>

        </div>

        <div class="form-row">
          <div class=" col-md-6">
            <b>Shipper</b>
            <tf-shipper-multi-select :customerId="loggedInUserCustomerId"
                                     :userId="loggedInUserId">
            </tf-shipper-multi-select>
          </div>
          <!--
        </div>

         <div class="form-row">
    <div class="col-md-6">
      <b>Shipper City</b>
      <tf-shipper-cities-multi-select>
      </tf-shipper-cities-multi-select>
    </div>

    <div class="col-md-6">
      <b>Shipper State</b>
      <tf-shipper-states-multi-select></tf-shipper-states-multi-select>
    </div>
  </div>



       <div class="form-row">
          -->
          <div class=" col-md-6">
            <b>Supplier</b>
            <tf-supplier-multi-select :customerId="loggedInUserCustomerId"
                                      :userId="loggedInUserId"></tf-supplier-multi-select>
          </div>
          
  </div>
          <!-- <div class="form-row">
        <div class="col-md-6">
          <b>Supplier City</b>
          <tf-supplier-cities-multi-select></tf-supplier-cities-multi-select>
        </div>

        <div class="col-md-6">
          <b>Supplier State</b>
          <tf-supplier-states-multi-select><label style="font-weight:bold" for="inputEmail4">Supplier State</label></tf-supplier-states-multi-select>
        </div>
      </div>
    -->

          <div class="form-row">
            <div class="col-md-6">
              <b>PO/Sales Order</b>
              <b-form-input id="txtPoSalesNumber"
                            v-model="filter.selectedPOs">
              </b-form-input>
            </div>

            <div class="col-md-6">
              <tf-commodity-multi-select :customerId="loggedInUserCustomerId"
                                         :userId="loggedInUserId">
              </tf-commodity-multi-select>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-12">
              <b>Notes</b>
              <b-form-textarea id="txtAdditionalNotes"
                               v-model="filter.selectedAdditionalNotes">
              </b-form-textarea>

            </div>
          </div>
</b-collapse>
      </b-card>
      <b-card>
        <b-card-title>
          <span
            @click="filter.datesExpanded = !filter.datesExpanded"
            :aria-expanded="false"
            aria-controls="collapse-dates"
            class="pointer">
            Advanced Date Fields
            <i :class="[
              filter.datesExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down',
              'pl-2'
              ]">
            </i>
          </span>
        </b-card-title>

        <b-collapse id="collapse-dates" v-model="filter.datesExpanded">
          <div class="form-row">
            <b-col md="6">
              <b>Created From</b>
              <tf-date-picker
                id="filterCreatedDateFrom"
                :selectedDate="filter.createdFrom"
                @onChange="filter.createdFrom = $event">
              </tf-date-picker>
            </b-col>
            <b-col md="6">
              <b>Created To</b>
              <tf-date-picker
                id="filterCreatedDateTo"
                :selectedDate="filter.createdTo"
                @onChange="filter.createdTo = $event">
              </tf-date-picker>
            </b-col>
          </div>
        </b-collapse>
      </b-card>

      <div class="form-row">
        <b-col>
          <b-button
            @click="applyTemplateFilters()"
            variant="primary"
            class="mr-3">
            Apply Filters
          </b-button>
          <b-button
            @click="clearTemplateFilters()"
            variant="outline-danger"
            class="mr-5">
            Clear Filters
          </b-button>
        </b-col>
      </div>
      <b-row v-if="filterError" class="text-danger justify-content-center">
        An error unexpected error has occurred.
        <br />Please try again later or contact support.
      </b-row>
    </div>
  </template>

  <script>

    import TfDatePicker from "@/components/order-tender/FilterDatePicker";
    import { mapActions, mapState, mapGetters } from "vuex";
    import Multiselect from "vue-multiselect";

    import TfBillToMultiSelect from "@/components/users/BillToMultiSelect";
    import TfAccountOfMultiSelect from "@/components/users/AccountOfMultiSelect";
    import TfSupplierMultiSelect from "@/components/users/SupplierMultiSelect";
    import TfSupplierCitiesMultiSelect from "@/components/multiselects/SupplierCitiesMultiSelect";
    import TfSupplierStatesMultiSelect from "@/components/multiselects/SupplierStatesMultiSelect";
    import TfShipperMultiSelect from "@/components/users/ShipperMultiSelect";
    import TfShipperCitiesMultiSelect from "@/components/multiselects/ShipperCitiesMultiSelect";
    import TfShipperStatesMultiSelect from "@/components/multiselects/ShipperStatesMultiSelect";

    import TfConsigneeMultiSelect from "@/components/users/ConsigneeMultiSelect";
    import TfCitiesMultiSelect from "@/components/multiselects/CitiesMultiSelect";
    import TfStatesMultiSelect from "@/components/multiselects/StatesMultiSelect";
    import TfCommodityMultiSelect from "@/components/users/CommodityMultiSelect";


    export default {
      name: "AdvanceTemplateFilters",
      components: {
        TfDatePicker,
        Multiselect,
        TfBillToMultiSelect,
        TfSupplierMultiSelect,
        TfSupplierCitiesMultiSelect,
        TfSupplierStatesMultiSelect,
        TfShipperMultiSelect,
        TfShipperCitiesMultiSelect,
        TfShipperStatesMultiSelect,
        TfAccountOfMultiSelect,
        TfConsigneeMultiSelect,
        TfCitiesMultiSelect,
        TfStatesMultiSelect,
        TfCommodityMultiSelect

      },
      data: () => {
       // const d = new Date();
        return {
          filterError: false,
          filter: {
            templateName: null,
            createdFrom: null,
            createdTo: null,
            selectedBillTos: [],
            selectedShippers: [],
            selectedShipperCities: [],
            selectedShipperStates: [],
            selectedSuppliers: [],
            selectedSupplierCities: [],
            selectedSupplierStates: [],
            selectedAccountOfs: [],
            selectedConsignees: [],
            selectedCities: [],
            selectedStates: [],
            getSelectedCommodities: [],
            selectedAdditionalNotes: null,
            selectedPOs: null,
            datesExpanded: false,
            advancedFieldsExpaned: false,
            basicFieldsExpaned: true
          },

          options: {
            format: "MM/DD/YYYY",
            useCurrent: false,
            showClear: true,
            showClose: true
          },


        };
      },
      mounted() {
        //load the objects back from state.

        this.filter = { ...this.filterObject };
        this.setSelectedBillTos(this.filter.selectedBillTos);
        this.setSelectedConsignees(this.filter.selectedConsignees);

        this.setSelectedShippers(this.filter.selectedShippers);
        this.setSelectedShipperCities(this.filter.selectedShipperCities);
        this.setSelectedShipperStates(this.filter.selectedShipperStates);
        this.setSelectedSuppliers(this.filter.selectedSuppliers);
        this.setSelectedSupplierCities(this.filter.selectedSupplierCities);
        this.setSelectedSupplierStates(this.filter.selectedSupplierStates);
        this.setSelectedAccountOfs(this.filter.selectedAccountOfs);
        this.setSelectedCities(this.filter.selectedCities);
        this.setSelectedStates(this.filter.selectedStates);
        this.setSelectedCommodities(this.filter.getSelectedCommodities);
      },

      methods: {
        ...mapActions("orderTemplate", [
          "setTemplateFilters",
          "setIsFilterApplied",
          "setPagination"
        ]),
        ...mapActions("companyMultiSelects", [
          "setSelectedBillTos",
          "setSelectedShippers",
          "setSelectedShipperCities",
          "setSelectedShipperStates",
          "setSelectedSuppliers",
          "setSelectedSupplierCities",
          "setSelectedSupplierStates",
          "setSelectedAccountOfs",
          "setSelectedConsignees",
          "setSelectedCities",
          "setSelectedStates",
          "setSelectedCommodities",
        ]),
        async applyTemplateFilters() {
          this.setPagination(1);

          this.setTemplateFilters(this.filter);
          this.setIsFilterApplied(true);
          this.$emit("closePanel", true); //true here will trigger reload of orders.
        },
        async clearTemplateFilters() {

          this.setPagination(1);

          this.filter = {
            templateName: null,
            createdFrom: null,
            createdTo: null,
            selectedBillTos: [],
            selectedShippers: [],
            selectedShipperCities: [],
            selectedShipperStates: [],
            selectedSuppliers: [],
            selectedSupplierCities: [],
            selectedSupplierStates: [],
            selectedAccountOfs: [],
            selectedConsignees: [],
            selectedCities: [],
            selectedStates: [],
            getSelectedCommodities: [],
            selectedPOs: null,
            selectedAdditionalNotes: null,

            datesExpanded: false,
            advancedFieldsExpaned: false,
            basicFieldsExpaned: true
          };


          this.setTemplateFilters(this.filter);
          this.setIsFilterApplied(false);

          this.setSelectedBillTos([]);
          this.setSelectedShippers([]);
          this.setSelectedShipperCities([]);
          this.setSelectedShipperStates([]);
          this.setSelectedSuppliers([]);
          this.setSelectedSupplierCities([]);
          this.setSelectedSupplierStates([]);
          this.setSelectedAccountOfs([]);
          this.setSelectedConsignees([]);
          this.setSelectedCities([]);
          this.setSelectedStates([]);
          this.setSelectedCommodities([]);


          this.$emit("closePanel", true); //true here will trigger reload of orders.
        },
      },
      computed: {
        ...mapGetters("oidcStore", ["oidcUser"]),
        ...mapGetters("user", ["loggedInUser"]),
        ...mapGetters({ getSelectedCommodities: "companyMultiSelects/getSelectedCommodities" }),
        ...mapState("orderTemplate", ["isFilterApplied", "filterObject"]),
        ...mapState("companyMultiSelects", [
          "selectedCities",
          "selectedStates",
          "selectedBillTos",
          "selectedShippers",
          "selectedShipperCities",
          "selectedShipperStates",
          "selectedSuppliers",
          "selectedSupplierCities",
          "selectedSupplierStates",
          "selectedAccountOfs",
          "selectedConsignees",
        ]),

        //   filterBasicSearchTerm: {
        //     get() { return this.$store.state.orderTemplate.searchTerm },
        //     set(value) { this.setSearchTerm(value) },

     // },

      orderRefNumberPopoverText() {
        return `<p>You can search for one or many order number or reference number in this box.</p>
              <p>You can separate order/reference numbers by spaces, commas, semicolons, ampersands, percent signs or underscores.</p>`;
      },
      orderFilterPopoverText() {
        return `<p>You can filter by any or all of the fields below.</p>
              <p>If no date ranges are specified the default delivery range will be set to 1 year prior to and 1 month after today.</p>`;
      },

      loggedInUserId() {
        return this.oidcUser.sub;
      },

      loggedInUserCustomerId() {
        return this.loggedInUser.customerId;
      },
    },
    watch: {
        selectedBillTos(newValue) {
          // can also pass in oldValue
        this.filter.selectedBillTos = newValue;
        },
        selectedConsignees(newValue) {
          this.filter.selectedConsignees = newValue;
        },
        selectedShippers(newValue) {
          this.filter.selectedShippers = newValue;
        },
        selectedShipperCities(newValue) {
          this.filter.selectedShipperCities = newValue;
        },
        selectedShipperStates(newValue) {
          this.filter.selectedShipperStates = newValue;
        },
        selectedSuppliers(newValue) {
          this.filter.selectedSuppliers = newValue;
      },
      selectedSupplierCities(newValue) {
        this.filter.selectedSupplierCities = newValue;
      },
      selectedSupplierStates(newValue) {
        this.filter.selectedSupplierStates = newValue;
      },
        selectedAccountOfs(newValue) {
          this.filter.selectedAccountOfs = newValue;
        },
        selectedCities(newValue) {
          this.filter.selectedCities = newValue;
        },
        selectedStates(newValue) {
          this.filter.selectedStates = newValue;
        },
        getSelectedCommodities(newValue) {
          this.filter.getSelectedCommodities = newValue;
        },
        selectedPOs(newValue) {
          this.filter.selectedPOs = newValue;
        },
        selectedAdditionalNotes(newValue) {
          this.filter.selectedAdditionalNotes = newValue;
        }
    }
  };
  </script>

  <style scoped>
    .dropdown-row {
      padding: 7.5px 0 7.5px 0;
    }

    .status-dropdown-row {
      padding: 7.5px 0 7.5px 20px;
    }

    .date-dropdown-row {
      padding: 0px 0 0 20px;
      /* max-width: 220px; */
    }

    .bottom-border {
      border-bottom: lightgrey solid 1px;
    }

    .top-border {
      border-top: lightgrey solid 1px;
    }

    .order-search .multiselect__select {
      height: 35px;
    }

    .order-search .multiselect__tags {
      min-height: 35px !important;
      padding: 4px 40px 0 8px !important;
    }

    .order-search .multiselect__placeholder {
      margin-bottom: 6px;
    }

    .order-search .multiselect__tag {
      margin-bottom: 0px;
    }
    .pointer {
      cursor: pointer;
    }
  </style>
