<template id="tmplTemplates">
  <section id="secTemplatesMain" class="container vld-parent">
    <loading id="ldingIsLoading" :active.sync="isLoading" :is-full-page="true" color="#114C8E"></loading>
    <b-row id="rowOrderTemplateSearch">
      <b-col id="colOrderHdr" lg="8" md="8" sm="12">
        <h2 id="txtOrderTemplateHrd" class="text-primary">Order Templates</h2>
      </b-col>
      <b-col id="colSearchTerms" lg="4" md="4" sm="12">
        <b-row id="rowSearchTerms">
          <b-input-group id="inptgrpSearchTerms">
            <b-form-input id="frmInptSearchTerms"
                          type="text"
                          v-model="filterObject.templateName"
                          @keyup.enter="getOrderTemplates(true)"
                          placeholder="Quick search by Template Name" />
            <b-input-group-append id="inptGrpApndClearSearch">
              <b-button id="btnClearSearch"
                        v-if="filterObject.templateName"
                        @click="clearSearchTerm()"
                        variant="danger">
                <i class="fas fa-times"></i>
              </b-button>
            </b-input-group-append>
            <b-input-group-append id="inptGrpApndGetTemplates">
              <b-button id="btngetTemplates" @click="getOrderTemplates(true)" variant="primary">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-row>
      </b-col>
    </b-row>
    <div class="text-right" id="filterButtonDiv">
      <b-button @click="showFiltersSlideOut()"
                :variant="filterButtonClass"
                class="my-2"
               
                id="btnFilter">{{ filterButtonText }}</b-button>
    </div> 
    <b-row id="rowTemplateCard" class="mt-2">
      <b-card id="cardTemplateCard" :class="{'webview-card': windowWidth >= 768, 'mobile-card': windowWidth < 768}">
        <b-row id="rowCreateNewTemplate" class="mb-4">
          <b-col id="colCreateNewTemplate">
            <b-button id="btnCreateNewTemplate" href="new-order-template" v-if="canCreateCustomerTemplates || canCreateAdHocOrders"
                      variant="primary" @click="navigateToNewTemplatePage() ">
              <i class="fa fa-plus mr-1"></i> Create New Template
            </b-button>
          </b-col>
        </b-row>
        <b-row id="rowResultTables" v-if="windowWidth >= 768" class="card-table-header-tr">
          <b-col id="colTemplateName" class="card-table-header-td" lg="3" md="3">Template Name</b-col>
          <b-col id="colBillTo" class="card-table-header-td" lg="3" md="3">Bill To</b-col>
          <b-col id="colDeliverySite" class="card-table-header-td" lg="3" md="3">Delivery Site</b-col>
        </b-row>

        <b-row id="rowNoOrders" v-if="total == 0"
               class="justify-content-center mt-3 font-weight-bold">There are no Order Templates to display.</b-row>

        <ViewOrderTemplateCard :orderTemplates="orderTemplates" :pageSize="pageSize" />

        <b-row id="rowPagination" v-if="total > 0" class="justify-content-center mt-3">
          <b-pagination id="pgnTemplates"
                        v-model="currentPage"
                         @change="onPaginationChange"
                        :total-rows="total"
                        :per-page="perPage"
                        first-number
                        last-number
                        aria-controls="my-table"
                        align="center"
                        @input="getOrderTemplates(false)"></b-pagination>
        </b-row>
        <b-row id="rowTotalOrders" v-if="total > 0" class="justify-content-center">Displaying Templates {{ templateStart }} to {{ templateEnd }} of {{ total }}</b-row>
      </b-card>
    </b-row>
    <slideout-panel id="orderTemplatesMainSlideoutPanel"></slideout-panel>
  </section>
</template>

<script>
  import { mapState } from "vuex";
  import { handleRequestError } from "@/shared/utils/response-error-handler";
  import Loading from "vue-loading-overlay";
  import ViewOrderTemplateCard from "@/components/order-template/ViewOrderTemplateCard";
  import "vue-loading-overlay/dist/vue-loading.css";
  import { canCreateCustomerTemplates, canCreateAdHocOrders } from "@/shared/utils/permissions";
  import FilterDropDownMenu from "@/components/order-template/FilterDropdownMenu.vue";

  export default {
    name: "Templates.vue",
    components: {
      Loading,
      ViewOrderTemplateCard,
      FilterDropDownMenu
    },

    data() {
      return {
        isLoading: false,
        reload: false,
        windowWidth: window.innerWidth,
        errorText: "",
        perPage: 10,
        totalRows: 1,
        templateStart: 1,
        templateEnd: 10
      };
    },

    async created() {
      this.loadOrderTemplates();
    },

    methods: {
      loadOrderTemplates() {
        this.isLoading = true;
        const requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          searchTerm: this.$store.state.orderTemplate.searchTerm,
          filters: this.filterObject,
          currentPage: this.$store.state.orderTemplate.currentPage,
          preferHeader: "return=freight-based"
        };
       
        this.$store.dispatch("orderTemplate/getAllOrderTemplates", requestData).catch((error) =>
        {
          var errorMessage = handleRequestError(error);
          this.$snotify.error(errorMessage, "Error");
        }).finally(() =>
        {
          this.getPageStartEnd();
          this.isLoading = false;
          // turn off state reload flag
          this.$store.state.orderTemplate.reload = false;
        });
      },

      getPageStartEnd() {
        this.templateStart = (this.perPage * this.currentPage) - (this.perPage - 1)
        if ((this.perPage * this.currentPage) <= this.total) {
          this.templateEnd = this.perPage * this.currentPage
        }
        else {
          this.templateEnd = this.total
        }
      },

      async getOrderTemplates(isSearch) {
        if (isSearch) this.currentPage = 1;
     
        this.loadOrderTemplates();
      },

      navigateToNewTemplatePage() {
        this.$router.push({ name: "new-order-template" });
      },

      showFiltersSlideOut() {
        const panelInstance = this.$showPanel({
          component: FilterDropDownMenu,
          openOn: 'right'
        });

        panelInstance.promise.then(result => {
          if (result) this.loadOrderTemplates();
        });

      },

      clearSearchTerm() {
        this.filterObject.templateName = "";
        this.searchTerm = "";
        this.loadOrderTemplates();
      },
      onPaginationChange(val) {

        this.$store.dispatch("orderTemplate/setPagination", val);
      },
    },
    watch: {
      '$store.state.orderTemplate.reload': function (reload) {
        if (reload) {
          this.getOrderTemplates(true);
        }
      },
     
    },
    computed: {
      ...mapState("orderTemplate", [
        "orderTemplates",
        "total",
        "pageSize",
        "isFilterApplied",
        "filterObject"
      ]),

      currentPage: {
        get() {
          return this.$store.state.orderTemplate.currentPage;
        },
        set(value) {
          this.$store.dispatch("orderTemplate/setPagination", value);
        }
      },

      searchTerm: {
        get() {
          return this.$store.state.orderTemplate.searchTerm;
        },
        set(value) {
          this.$store.dispatch("orderTemplate/setSearchTerm", value);
        }
      },

      canCreateCustomerTemplates() {
        return canCreateCustomerTemplates(this.$store)
      },

      canCreateAdHocOrders() {
        return canCreateAdHocOrders(this.$store);
      },

      filterButtonClass() {
        if (this.isFilterApplied) {
          return "success";
        }
        return "outline-primary";
      },

      filterButtonText() {
        if (this.isFilterApplied) {
          return "View Applied Filters";
        }
        return "Advanced Filters";
      },
     
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener("resize", () => {
          this.windowWidth = window.innerWidth;
        });
      });
    }
  }
</script>

<style scoped>

  /* IE10+ */
  ::-ms-clear {
    display: none;
  }

  /* Chrome */
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

</style>
